import richText from "utilities/richText"
import Waves from "public/assets/hero-waves.svg"
import Image from "components/Image"

export default function ImageAndTextHero({ blok }) {
  return (
    <section className="relative h-max lg:mb-12">
      <div className="relative w-full h-full -z-20 path-home-hero-wrapper">
        <Image
          layout="responsive"
          src={blok.background_image.filename}
          alt={blok.background_image.alt}
          width={1440}
          height={696}
          priority
          className="path-home-hero relative -z-20 w-full h-[280px] lg:min-h-[646px] lg:max-h-[919px] object-cover object-right lg:object-left"
        />

        <div className="w-full h-[16vw] z-0 -bottom-4 sm:-bottom-7 lg:-bottom-14 absolute">
          <Waves className="absolute top-0 left-0 w-full h-full" aria-hidden />
        </div>
      </div>
      <section className="px-8 relative lg:absolute top-0 z-20 left-1/2 -translate-x-1/2 w-full ">
        <div className="max-w-screen-xl mx-auto border-b-blue border-b lg:border-b-0">
          <div className="max-w-2xl lg:max-w-min pt-6 pb-12 lg:py-24">
            <h1 className="text-l1 lg:text-xl1 sm:whitespace-nowrap">{blok.heading}</h1>
            <h2 className="bg-blue text-center text-m2 py-2 mt-2 mb-4 px-2">{blok.subheading}</h2>
            <div className="prose-p:leading-7 prose-p:font-light lg:prose-p:font-normal prose-p:pb-2">
              {richText(blok.content)}
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}
